jQuery( document ).ready(function($) {

  // videos
  $('#videos img').click(function(){
    const videoID = $(this).attr('alt')
    $('.featured-video').attr('src', `https://www.youtube.com/embed/${videoID}?controls=0`)
  })

  // View all news
  $('.all-news').click(function(e){
    e.preventDefault()
    $('#news .wrapper').toggleClass('all')
    if($(this).html() === 'view all'){
      $(this).html('view less')
    } else{
      $(this).html('view all')
    }
  })

  // Newsletter
  $("#tyler-thompson-newsletter-sign-up-website-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
      withCredentials: false
      },
      success: function (data) {
        $('.newsletter-alert.success').show()
        $('.newsletter-alert.error').hide()
        $('.newsletter form').hide()
        $('.newsletter h3').hide()
      },
      error: function (err) {
        $('.newsletter-alert.error').show()
      }
    });
  });

});